import React, {PropsWithChildren, useContext} from "react";
import {Redirect, Route} from 'react-router-dom';
import {AuthContext, IAuthContext} from "../../context/AuthContext";

export type IPrivateRouteProps = {
    path: string,
}

const PrivateRoute = (props: PropsWithChildren<IPrivateRouteProps>) => {
    const context = useContext<IAuthContext>(AuthContext);

    return context.loaded ? (
        <Route>
            {context.authenticated ? props.children : <Redirect to={{pathname: "/login"}}/>}
        </Route>
    ) : null;
}

export default PrivateRoute;