import {Col, Row} from "react-bootstrap";
import React from "react";

interface ISubCategoriesProps {
    items: any,
}

export const SubCategories = (props: ISubCategoriesProps) => {
    return <>
        {Object.keys(props.items).map((category) => {
            return <Row key={category} className={"striped mb-4"}>
                <Col className={"col-12 mb-1 text-center wisdom-font"}>
                    <h2>{category}</h2>
                </Col>
                {Object.keys(props.items[category]).map((item) => {
                    return <Col key={item} className={"col-12 mb-1 px-4"}>
                        <div className={"d-flex align-items-end item"}>
                            <h5>• {item}</h5>
                            <h5 className={"ml-auto"}>{props.items[category][item]}€</h5>
                        </div>
                    </Col>
                })}
            </Row>
        })}
    </>
}