import {SubCategories} from "./SubCategories";
import React, {useEffect, useState} from "react";
import socket from "../context/SocketIOInstance";

interface IBoissonsState {
    boissons: { [item: string]: { [item: string]: number } },
}

const initialState: IBoissonsState = {
    boissons: {},
}

export const Boissons = () => {
    const [state, setState] = useState<IBoissonsState>(initialState);

    useEffect(function onMount() {
        socket.emit('retrieve_boissons');
        socket.on('boissons', (boissons) => {
            setState(prevState => ({...prevState, boissons: boissons}));
        });
    }, []);

    useEffect(() => {
        if (!Object.keys(state.boissons).length) return;
        window.onafterprint = function () {
            window.close();
        };
        setTimeout(window.print, 500);
    }, [state.boissons]);

    return (
        <div style={{backgroundColor: "white"}}>
            <SubCategories items={state.boissons}/>
        </div>
    )
}