import * as React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import "./App.css";
import PrivateRoute from "./components/routing/PrivateRoute";
import {Home} from "./components/Home";
import {EditPanel} from "./components/EditPanel";
import {Login} from "./components/auth/Login";
import {useClearCache} from 'react-clear-cache';
import {Boissons} from './components/Boissons';

export const updateMessage = "Ajout d'un bouton pour imprimer les boissons";

const App = () => {
    useClearCache({auto: true});
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/boissons">
                    <Boissons/>
                </Route>
                <Route path="/login">
                    <Login/>
                </Route>
                <PrivateRoute path="/admin">
                    <EditPanel/>
                </PrivateRoute>
                <Redirect to="/"/>
            </Switch>
        </Router>
    );
};

export default App;