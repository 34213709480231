import React, {Component, createContext, PropsWithChildren} from 'react';
import socket from "./SocketIOInstance";

export interface IAuthContext {
    authenticated: boolean,
    password: string,
    loaded: boolean,
    setAuthenticated: (value: boolean) => void,
    setPassword: (password: string) => void,
    disconnect: () => void,
}

const initialState: IAuthContext = {
    authenticated: false,
    password: "",
    loaded: false,
    setAuthenticated: () => {
    },
    setPassword: () => {
    },
    disconnect: () => {
    },
}

export const AuthContext = createContext<IAuthContext>(initialState);

class AuthContextProvider extends Component<{}, IAuthContext> {

    constructor(props: PropsWithChildren<{}>) {
        super(props);

        const prevPassword: string = window.localStorage.getItem('password') || "";

        this.state = {
            authenticated: false,
            password: prevPassword,
            loaded: false,
            setAuthenticated: this.setAuthenticated.bind(this),
            setPassword: this.setPassword.bind(this),
            disconnect: this.disconnect.bind(this)
        };
    }

    componentDidMount() {
        socket.emit("login", this.state.password);
        socket.on("login_info", (success) => {
            if (success) this.setAuthenticated(true);
            else this.disconnect();
            this.setState({loaded: true});
        });
    }

    setAuthenticated(value: boolean) {
        this.setState({authenticated: value});
        window.localStorage.setItem('authenticated', JSON.stringify(value));
    }

    setPassword(password: string) {
        this.setState({password: password});
        window.localStorage.setItem('password', password);
    }

    disconnect() {
        this.setState({
            authenticated: false,
            password: "",
        });
        window.localStorage.removeItem('password');
    }

    render() {
        const contextData = this.state;

        return (
            <AuthContext.Provider value={contextData}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthContextProvider;