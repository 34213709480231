import Modal from "react-bootstrap/Modal";
import {Col, Row} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import React from "react";
import {SubCategories} from "./SubCategories";

interface ISubCategoriesModalProps {
    onHide: () => void,
    show: boolean,
    items: any,
}

export const SubCategoriesModal = (props: ISubCategoriesModalProps) => {
    return (
        <Modal {...props} style={{height: "100vh", width: "100vw"}} show={props.show} className={"noprint"} centered>
            <Row>
                <Col className={"text-right"}>
                    <X size={"60px"} className={"cursor-pointer m-3"} onClick={props.onHide}/>
                </Col>
            </Row>
            <Modal.Body className={"pt-0"}>
                <SubCategories items={props.items}/>
            </Modal.Body>
        </Modal>
    );
}